import React, { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar as MuiAppBar, Button, Container, Stack, Box, Toolbar, Typography, useScrollTrigger, List, ListItem } from '@mui/material';

// project imports
import Logo from 'assets/images/landing/logo.svg';
import DarkLogo from 'assets/images/landing/DarkLogo.svg';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Layout from 'layout/Customization/Layout';

// elevation scroll
interface ElevationScrollProps {
    children: ReactElement;
    window?: Window | Node;
}
function ElevationScroll({ children, window }: ElevationScrollProps) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window!
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}

const menuItems = [
    {
        name: 'Home',
        route: '/'
    },
    {
        name: 'NFTs',
        route: '/nfts'
    },
    {
        name: 'Assets',
        route: '/assets'
    },
    {
        name: 'Platforms',
        route: '/platforms'
    }
];

interface TickerData {
    asset_count: number;
    platform_count: number;
    total_tvl: number;
    max_apr: number;
    top_wbtc: number;
    top_eth: number;
    max_apr_asset: string;
}
const tickerDefault = {
    asset_count: 0,
    platform_count: 0,
    total_tvl: 0,
    max_apr: 0,
    top_wbtc: 0,
    top_eth: 0,
    max_apr_asset: ''
};

const Header = () => {
    const theme = useTheme();
    const location = useLocation();
    const pathname = location.pathname;
    const [ticker, setTicker] = useState(tickerDefault);

    useEffect(() => {
        getTicket();
    }, []);

    const getTicket = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/ticker`).then((res) => {
            setTicker({
                asset_count: res.data.asset_count ? res.data.asset_count : 0,
                platform_count: res.data.platform_count ? res.data.platform_count : 0,
                total_tvl: res.data.total_tvl ? res.data.total_tvl : 0,
                max_apr: res.data.max_apr.length ? res.data.max_apr[0].apr : 0,
                top_wbtc: res.data.top_wbtc.length ? res.data.top_wbtc[0].apr : 0,
                top_eth: res.data.top_eth.length ? res.data.top_eth[0].apr : 0,
                max_apr_asset: res.data.max_apr.length ? res.data.max_apr[0].symbol : 0
            });
        });
    };

    return (
        <>
            <Box component="div" className="topbar">
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} className="ticker-container">
                        <List>
                            <TableRow className="header-list-row">
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        Assets{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to="/assets">
                                                {ticker.asset_count ? ticker.asset_count.toLocaleString() : '0'}
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        Platforms{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to="/platforms">
                                                {ticker.platform_count ? ticker.platform_count.toLocaleString() : '0'}
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        TVL{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to="/platforms">
                                                $
                                                {ticker.total_tvl
                                                    ? ticker.total_tvl.toLocaleString(undefined, { maximumFractionDigits: 0 })
                                                    : '0'}
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        Max APR{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to={`assets/${ticker.max_apr_asset}`}>
                                                {ticker.max_apr
                                                    ? ticker.max_apr.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                    : '0'}
                                                %
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        wBTC Yield{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to="/assets/wbtc">
                                                {ticker.top_wbtc
                                                    ? ticker.top_wbtc.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                    : '0'}
                                                %
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                                <TableCell className="header-td">
                                    <ListItem className="header-list-item">
                                        ETH Yield{' '}
                                        <Box component="span">
                                            <Button color="inherit" component={RouterLink} to="/assets/eth">
                                                {ticker.top_eth
                                                    ? ticker.top_eth.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                    : '0'}
                                                %
                                            </Button>
                                        </Box>
                                    </ListItem>
                                </TableCell>
                            </TableRow>
                        </List>
                    </Table>
                </TableContainer>
            </Box>
            <Box component="div" className="appheader">
                <ElevationScroll>
                    <MuiAppBar>
                        <Container>
                            <Toolbar>
                                <div>
                                    <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                                        {theme.palette.mode == 'dark' ? (
                                            <img src={DarkLogo} alt="" width="250" />
                                        ) : (
                                            <img src={Logo} alt="" width="250" />
                                        )}
                                    </Typography>
                                    <Stack
                                        className="app-header-menu"
                                        direction="row"
                                        sx={{ display: { xs: 'none', sm: 'block' } }}
                                        spacing={2}
                                    >
                                        {menuItems.map((item, index) => (
                                            <Button
                                                color="inherit"
                                                component={RouterLink}
                                                to={item.route}
                                                key={index}
                                                className={item.route.toLowerCase() == pathname.toLowerCase() ? 'active' : ''}
                                            >
                                                {item.name}
                                            </Button>
                                        ))}
                                    </Stack>
                                </div>
                                <Box component="div" className="header-options">
                                    <Layout />
                                </Box>
                            </Toolbar>
                        </Container>
                    </MuiAppBar>
                </ElevationScroll>
            </Box>
        </>
    );
};

export default Header;
