import React, { ReactElement, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Checkbox,
    Container,
    FormLabel,
    Input,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material';

// project imports
import Logo from 'assets/images/landing/logo.svg';

// elevation scroll
interface ElevationScrollProps {
    children: ReactElement;
    window?: Window | Node;
}
function ElevationScroll({ children, window }: ElevationScrollProps) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window!
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}
const label = { inputProps: { 'aria-label': 'menu_toggle' } };

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };
    return (
        <Box component="div" className="webheader">
            <ElevationScroll>
                <MuiAppBar>
                    <Container>
                        <Toolbar className="menubar">
                            <Typography className="logo" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                                <img src={Logo} alt="" width="250" />
                            </Typography>
                            <Box
                                className={showMenu ? 'toggle show' : 'toggle'}
                                component="div"
                                id="toggle"
                                onClick={(e: any) => handleMenuToggle()}
                            >
                                <Box component="span" />
                            </Box>
                            <Checkbox className="menu__btn" {...label} id="menu__toggle" />
                            <Stack
                                className={showMenu ? 'nav show' : 'nav'}
                                direction="row"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                                spacing={2}
                            >
                                <Button className="title" color="inherit">
                                    Menu
                                </Button>
                                <HashLink color="inherit" to="/#">
                                    Home
                                </HashLink>
                                <HashLink color="inherit" to="/#about">
                                    Who We Are
                                </HashLink>
                                <HashLink color="inherit" to="/#platform">
                                    App
                                </HashLink>
                                <HashLink color="inherit" to="/#education">
                                    Education
                                </HashLink>
                                <HashLink color="inherit" to="/#chat">
                                    Partnership
                                </HashLink>
                            </Stack>
                            <Button className="launch-btn" color="inherit" component={RouterLink} to="/assets" target="_blank">
                                Launch App
                            </Button>
                        </Toolbar>
                    </Container>
                </MuiAppBar>
            </ElevationScroll>
        </Box>
    );
};

export default Header;
