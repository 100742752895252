import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AppLayout from 'layout/AppLayout';

// sample page routing

const YetiList = Loadable(lazy(() => import('views/pages/YetiList')));
const Assets = Loadable(lazy(() => import('views/pages/Assets')));
const AssetsDetails = Loadable(lazy(() => import('views/pages/AssetsDetails')));
const AssetsPoolDetails = Loadable(lazy(() => import('views/pages/AssetsPool')));
const Platforms = Loadable(lazy(() => import('views/pages/Platforms')));
const PlatformDetails = Loadable(lazy(() => import('views/pages/PlatformDetails')));
const NFT = Loadable(lazy(() => import('views/pages/Nft')));
const AssetsLendingPool = Loadable(lazy(() => import('views/pages/AssetslendingPool')));
// ==============================|| MAIN ROUTING ||============================== //

const AppRoutes = {
    path: '/',
    element: <AppLayout />,
    children: [
        {
            path: '/yetilist',
            element: <YetiList />
        },
        {
            path: '/assets',
            element: <Assets />
        },
        {
            path: '/platforms',
            element: <Platforms />
        },
        {
            path: '/nfts',
            element: <NFT />
        },
        {
            path: '/assets/:asset',
            element: <AssetsDetails />
        },
        {
            path: '/assets/:asset/:platform',
            element: <AssetsPoolDetails />
        },
        {
            path: '/assets/lending/:asset/:platform',
            element: <AssetsLendingPool />
        },
        {
            path: '/platforms/:platform/:blockchain',
            element: <PlatformDetails />
        }
    ]
};

export default AppRoutes;
