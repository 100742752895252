import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Drawer, Fab, Grid, IconButton, Tooltip } from '@mui/material';
import { IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import BorderRadius from './BorderRadius';
import Layout from './Layout';
import PresetColor from './PresetColor';
import FontFamily from './FontFamily';
import InputFilled from './InputFilled';
import BoxContainer from './BoxContainer';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = () => {
    const theme = useTheme();

    // drawer on/off
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            {/* toggle button */}
            <Fab
                component="div"
                onClick={handleToggle}
                size="medium"
                color="inherit"
                className="switch-theme-toggle-container"
                sx={{
                    borderRadius: 0,
                    top: 82,
                    position: 'absolute',
                    right: 86,
                    width: 'max-content',
                    zIndex: theme.zIndex.speedDial
                }}
            >
                {/* <Layout /> */}
            </Fab>
        </>
    );
};

export default Customization;
