// material-ui
import { useTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, PaletteMode } from '@mui/material';

// project imports
import useConfig from 'hooks/useConfig';
import SubCard from 'ui-component/cards/SubCard';
import { Box } from '@mui/system';
import { IconMoon, IconSun } from '@tabler/icons';

const Layout = () => {
    const theme = useTheme();
    const { navType, rtlLayout, onChangeMenuType, onChangeRTL } = useConfig();

    const handleChange = (e: any) => {
        if (navType == 'dark') {
            onChangeMenuType('light' as PaletteMode);
        } else {
            onChangeMenuType('dark' as PaletteMode);
        }
    };

    return (
        <Box component="div" className="switch-theme-toggle">
            <Box component="div" className="switch-theme-btn" onClick={(e: any) => handleChange(e)}>
                {navType == 'dark' ? <IconSun strokeWidth="2" stroke="white" /> : <IconMoon strokeWidth="0" />}
            </Box>
        </Box>
    );
};

export default Layout;
