import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const HomePage = Loadable(lazy(() => import('views/pages/Home')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/pages/PrivacyPolicy/PrivacyPolicy')));
const Terms = Loadable(lazy(() => import('../views/pages/Terms-Conditions/Terms')));
const Disclaimer = Loadable(lazy(() => import('../views/pages/Disclaimer/Disclaimer')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/privacy',
            element: <PrivacyPolicy />
        },
        {
            path: '/terms',
            element: <Terms />
        },
        {
            path: '/disclaimer',
            element: <Disclaimer />
        }
    ]
};

export default MainRoutes;
