import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBar as MuiAppBar, Button, Container, Stack, Box, Toolbar, Typography, useScrollTrigger, List, ListItem } from '@mui/material';
import Logo from 'assets/images/landing/ftr-logo.png';
import discord from 'assets/images/icons/discord.png';
import gitbook from 'assets/images/icons/gitbook.png';
import twitter from 'assets/images/icons/twitter.png';
import linkedin from 'assets/images/icons/linkedin.png';
import instagram from 'assets/images/icons/instagram.png';
import tiktok from 'assets/images/icons/tiktok.png';
import medium from 'assets/images/icons/medium.png';

const date = new Date();
const year = date.getFullYear();

const Footer = () => (
    <Box component="div" className="app-copyright">
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: 1
                }}
                className="footer-box"
            >
                <Box className="footer-copyright-menu-links-box">
                    <Box component="span" className="footer-copyright-year">
                        <p>Copyright {year} DefiSherpa Inc.</p>
                    </Box>
                    <Box component="span" className="footer-menu-links">
                        <Button className="footer-menu-link-a" color="inherit" component={RouterLink} to="/privacy">
                            Privacy Policy
                        </Button>
                        <Button className="footer-menu-link-a" color="inherit" component={RouterLink} to="/disclaimer">
                            Disclaimer
                        </Button>
                        <Button className="footer-menu-link-a" color="inherit" component={RouterLink} to="/terms">
                            Terms and Conditions
                        </Button>
                    </Box>
                </Box>
                <Box
                    component="span"
                    className="footer-social-media"
                    sx={{
                        display: 'inline-block',
                        justifyContent: 'flex-end'
                    }}
                >
                    <div className="footer-btn-div">
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://discord.com/invite/85ectMrfn5">
                            <img className="footer-social-img" src={discord} width="32" />
                        </Button>
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://defisherpa.gitbook.io/defisherpa/">
                            <img className="footer-social-img" src={gitbook} width="32" />
                        </Button>
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://twitter.com/defisherpa">
                            <img className="footer-social-img" src={twitter} width="32" />
                        </Button>
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://www.linkedin.com/company/defisherpa/">
                            <img className="footer-social-img" src={linkedin} width="32" />
                        </Button>
                    </div>
                    <div className="footer-btn-div">
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://www.instagram.com/defisherpa/">
                            <img className="footer-social-img" src={instagram} width="32" />
                        </Button>
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://www.tiktok.com/@defisherpa">
                            <img className="footer-social-img" src={tiktok} width="32" />
                        </Button>
                        <Button className="footer-btn" color="inherit" target="_blank" href="https://medium.com/defisherpa">
                            <img className="footer-social-img" src={medium} width="32" />
                        </Button>
                    </div>
                </Box>
            </Box>
        </Container>
    </Box>
);

export default Footer;
