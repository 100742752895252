import { Link } from 'react-router-dom';
import ftrLogo from 'assets/images/landing/Footer-Logo.svg';

// material-ui
import { Container, Grid, Box, List, ListItem } from '@mui/material';

const date = new Date();
const year = date.getFullYear();

const Footer = () => (
    <footer>
        <Container>
            <Grid className="flex-container" container>
                <Grid className="ftr-left" item xs={6}>
                    <img src={ftrLogo} alt="" />
                    <p>
                        DefiSherpa is a Web3 data compiler that showcases historical information on a growing repository of liquidity pools,
                        staking/yield opportunities, NFTs, and more!
                    </p>
                </Grid>
                <Grid className="ftr-right" item xs={6}>
                    <Box component="div">
                        <h6>Resources</h6>
                        <List>
                            <ListItem>
                                <a href="https://discord.gg/qvcRQj37PX" target="_blank">
                                    Discord Server
                                </a>
                            </ListItem>
                            <ListItem>
                                <Link to="/privacy">Privacy Policy</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/disclaimer">Disclaimer</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/terms">Terms and Conditions</Link>
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <Box component="div" id="copywrite">
            <Container>
                <p>Copyright {year} DefiSherpa Inc.</p>
            </Container>
        </Box>
    </footer>
);

export default Footer;
